<template>
  <div class="DutyList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" v-if="!getShowRegionName()"></v-button>
        <!-- <v-button text="新增" permission="add" @click="create" ></v-button> -->
      </template>
      <template #searchSlot>
        <v-select2 v-if="getShowRegionName()" label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" @onChange="onChange"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams" @onChange="onChange" :subjoin="communitySubjoin"></v-select2>
        <v-select label="服务大类" v-model="searchParams.volunteerCat" :options="typeIdOps" :disabled="!searchParams.regionId&&!searchParams.communityId"></v-select>
        <v-input label="岗位名称" v-model="searchParams.volunteerDutys"></v-input>
        <v-select label="岗位状态" v-model="searchParams.isExamine" :options="statusOps"></v-select>
        <v-datepicker label="添加时间段" :maxDate="maxDate" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" v-if="!getShowRegionName()"></v-button>
        <v-button text="查看" type="text" @click="look(scope.row)" v-if="getShowRegionName()"></v-button>
      </template>
    </list>
  </div>
</template>
<script>
import { getListURL, getRegionsURL, getCategoryListURL } from './api'
import { statusMap, setStatusOps } from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'DutyList',
  data () {
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      maxDate: new Date(),
      communityParams,
      regionParams: {
        searchUrl: getRegionsURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        }
      },
      searchParams: {
        volunteerDutys: '',
        volunteerCat: undefined,
        isExamine: undefined,
        communityId: '',
        startDate: '',
        endDate: '',
        regionId: ''
      },
      typeIdOps: [{
        text: '全部',
        value: undefined
      }],
      headers: [
        {
          prop: 'volunteerDuty',
          label: '岗位名称'
        },
        {
          prop: 'code',
          label: '服务大类'
        },
        {
          prop: 'statusText',
          label: '岗位状态',
          formatter (row) {
            return statusMap[row.isExamine]
          }
        },
        {
          prop: 'introduce',
          label: '岗位说明'
        },
        {
          prop: 'intime',
          label: '添加时间'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司',
          show: this.getShowRegionName()
        }
      ]
    }
  },
  created () {
    //this.getTypeIdData()
  },
  computed: {
    communitySubjoin () {
      return {
        regionId: this.searchParams.regionId
      }
    },
  },
  methods: {
    // 根据账户角色权限，获取是否要显示所属分公司
    getShowRegionName () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    },
    getTypeIdData () {
      const _this = this
      _this.typeIdOps= [
        {
          text: '全部',
          value: undefined
        }
      ]
      let params = {
        tenantId:this.searchParams.regionId,
        communityId:this.searchParams.communityId
      }
      for(let k in params) {
        if(!params[k]) {
          delete params[k]
        }
      }
      this.$axios.get(getCategoryListURL,{params}).then(res => {
        if (res.status == '100') {
          const data = res.data
          data.forEach(item => {
            _this.typeIdOps.push({
              text: item.categoryName,
              value: item.id
            })
          })
        }
      })
    },
    onChange(val) {
      this.searchParams.volunteerCat = undefined
      if(val) {
        this.getTypeIdData()
      }  
    },
    create () {
      this.$router.push({
        name: 'dutyForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'dutyForm',
        query: {
          id: row.id
        }
      })
    },
    look (row) {
      this.$router.push({
        name: 'dutyForm',
        query: {
          id: row.id,
          look: 1
        }
      })
    }
  }
}
</script>
