var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DutyList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: false,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                !_vm.getShowRegionName()
                  ? _c("v-button", {
                      attrs: { text: "新增", permission: "add" },
                      on: { click: _vm.create },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _vm.getShowRegionName()
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属公司" },
                          on: { onChange: _vm.onChange },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.regionParams,
                        false
                      )
                    )
                  : _vm._e(),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "所属项目",
                        subjoin: _vm.communitySubjoin,
                      },
                      on: { onChange: _vm.onChange },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: {
                    label: "服务大类",
                    options: _vm.typeIdOps,
                    disabled:
                      !_vm.searchParams.regionId &&
                      !_vm.searchParams.communityId,
                  },
                  model: {
                    value: _vm.searchParams.volunteerCat,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "volunteerCat", $$v)
                    },
                    expression: "searchParams.volunteerCat",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "岗位名称" },
                  model: {
                    value: _vm.searchParams.volunteerDutys,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "volunteerDutys", $$v)
                    },
                    expression: "searchParams.volunteerDutys",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "岗位状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.isExamine,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isExamine", $$v)
                    },
                    expression: "searchParams.isExamine",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "添加时间段",
                    maxDate: _vm.maxDate,
                    startTime: _vm.searchParams.startDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                !_vm.getShowRegionName()
                  ? _c("v-button", {
                      attrs: {
                        text: "编辑",
                        type: "text",
                        permission: "update",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.getShowRegionName()
                  ? _c("v-button", {
                      attrs: { text: "查看", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.look(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }